import axios from 'axios';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { NodePOSTApi } from '../../../Constant/api';
import { slugs } from '../../../Constant/slugs';

export default function PaymentVerificationBatch(props) {
  const location = useLocation()
  const history = useHistory()

  const [reload, setReload] = React.useState(false)
  const [responseReturn, setResponseReturn] = React.useState(true)

  const TESTSuccess = () => {
    const ExamID = location.pathname.slice(15)
    const Buy = location.pathname.slice(14)
    const pid = localStorage.getItem("pid")
    const Amount =  localStorage.getItem("AmountPaid")

    setReload(true)
    // axios({
    //   method: "POST",
    //   url: `http://localhost:4000/esewa_payment_verification_batch`,
    //   data: {
    //     pid: pid,
    //     rid: rid,
    //     Amount: Number(Amount),
    //     examID: ExamID,
    //     buy: "true"
    //   }
    // })
    NodePOSTApi({
      pid: pid,
      rid: pid,
      Amount: Number(Amount),
      examID: ExamID,
      buy: "true"
    }, "esewa_payment_verification_batch")
      .then((res) => {
        console.log('Success:', res.data);
        setResponseReturn(res.data.success)
        setReload(false)
        if (res.data.success) {
          history.push(`${slugs.daily_exam}`)
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      }).finally(() => {
        setReload(false)
      })
  }
  useEffect(() => {
    // if (
    //   location.search.search('refId') !== -1 &&
    //   location.search.search('oid') !== -1 &&
    //   location.search.search('amt') !== -1
    // )
      TESTSuccess()
  }, [])
  return (
    <div>
      {/* {location.search.slice(location.search.search("buy") + 4, location.search.search("exam") - 1)} */}
      {!responseReturn &&
        "Contact Admin for further process"

      }
      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}