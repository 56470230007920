import React, { useEffect } from "react";
import { AdsGETApiAuth } from "../../../../Constant/api";
import { slugs } from "../../../../Constant/slugs";

export default function ExamQuestionModal(props) {
  const {
    setExamName,
    ExamId, setExamID, setExamModal, setAmountToPay
  } = props;
  const [reload, setReload] = React.useState(false)
  const [Projects1, setProjects1] = React.useState([])
  const [searchExamID, setSearchExamID] = React.useState("")
  const [searchExamName, setSearchExamName] = React.useState("")

  const [UserArray, setUserArray] = React.useState([])
  const GetAllProjects = (params, slug) => {
    setReload(true)
    setProjects1([])
    AdsGETApiAuth(params, slug)
      .then((res) => {
        setProjects1(res.data)
        setUserArray(res.data)
      })
      .catch(err => {
      }).finally(() => {
        setReload(false)
      })
  }
  useEffect(() => {
    GetAllProjects({
      per_page: 1000,
      _fields: "id,title,slug,meta"
    }, slugs.db_slug_batch)
  }, [])
  return (
    <div>
      <div className="col-md-6 row">
        <div className="col-1" />
        <input className="col-3" style={{ margin: "1px 2px", padding: "3px 2px" }}
          value={searchExamID} placeholder="Exam ID"
          onChange={(event) => {
            setSearchExamID(event.target.value)
            setProjects1(UserArray.filter(a => a.id.includes(event.target.value)))
          }}
        />
        <input className="col-4" style={{ margin: "1px 2px", padding: "3px 2px" }}
          value={searchExamName} placeholder="Exam Name"
          onChange={(event) => {
            setSearchExamName(event.target.value)
            setProjects1(UserArray.filter(a => a.title.rendered.toLowerCase().includes(event.target.value.toLowerCase())))
          }}
        />
      </div>
      <table>
        <tbody>
          <tr>
            <td colSpan={6}
              onClick={() => {
                setExamID("")
                setExamName("")
                setExamModal(false)
              }}>
              Clear
            </td>
          </tr>
          {Projects1.map((item, index) => (
            <tr key={index}
              onClick={() => {
                setExamID(item.id)
                setExamName(item.title.rendered)
                setExamModal(false)
                setAmountToPay(item.meta.batch_price)
              }}
            >
              <>
                <td>
                  {item.id == ExamId ?
                    <i className="fa fa-check-square-o" />
                    :
                    <i className="fa fa-square-o" />
                  }
                </td>
                <td>{item.id}</td>
                <td>{item.title.rendered}</td>
                <td>{item.meta.batch_price}</td>
                <td>{item.meta.exam_reset_price}</td>
                <td>{item.meta.batch_discount}</td>
                <td>{item.meta.batch_start_date}</td>
                <td>{item.meta.batch_end_date}</td>
                <td>{item.meta.batch_status}</td>
                <td style={{ cursor: "pointer" }}

                >Choose</td>
              </>

            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}